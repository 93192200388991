import MessageHistoryItem from 'Common/Models/message-history-item.model';
import ContactMiniModel from './contact-mini.model';
import {
    ContactBoxMode,
    SearchMetadata,
    SelectedContactModel,
} from './selected-contact.model';
import ContactBody from './contact-body.model';
import RecentContactMessage from './recentcontact-message.model';

export default class RecentContactModel {
    message: RecentContactMessage;

    unreadMessages: number;

    contact: ContactBody;

    readPointer: string;

    constructor(recentContact?: any) {
        this.message = recentContact?.message
            ? recentContact.message
            : ({} as RecentContactMessage);
        this.unreadMessages = recentContact.unreadMessages ?? 0;
        this.contact = recentContact?.contact
            ? recentContact.contact
            : ({} as ContactBody);
        this.readPointer = recentContact.readPointer ?? '';
    }

    isSameContact(contact: ContactBody): boolean {
        if (
            contact.opportunityInstanceId &&
            this.contact.opportunityInstanceId
        ) {
            return (
                contact.opportunityInstanceId ===
                this.contact.opportunityInstanceId
            );
        }
        return false;
    }

    isSameContactSelected(contact: SelectedContactModel): boolean {
        if (
            contact.opportunityInstanceId &&
            this.contact.opportunityInstanceId
        ) {
            return (
                contact.opportunityInstanceId ===
                this.contact.opportunityInstanceId
            );
        }
        return false;
    }

    toContactMini(): ContactMiniModel {
        return {
            id: this.contact.contactId,
            type: this.contact.contactType,
        } as ContactMiniModel;
    }

    toSelectedContact(
        searchMetadata: SearchMetadata | null,
        mode: ContactBoxMode = ContactBoxMode.Maximized
    ): SelectedContactModel {
        return {
            id: this.contact.contactId,
            opportunityInstanceId: this.contact.opportunityInstanceId,
            type: this.contact.contactType,
            Mode: mode,
            SearchMetadata: searchMetadata,
        } as SelectedContactModel;
    }

    isSameRecentContact(rcontact: RecentContactModel): boolean {
        if (
            rcontact?.contact.opportunityInstanceId &&
            this.contact.opportunityInstanceId
        ) {
            return (
                this.contact.opportunityInstanceId ===
                rcontact.contact.opportunityInstanceId
            );
        }
        return false;
    }
}
