import React, { createContext, useReducer } from 'react';
import MXLogger from 'Common/MXLogger';
import { ContactBoxMode } from 'Common/Models/selected-contact.model';
import MXCache from 'Common/CacheManager/MXCache';
import { AppTypeEnum } from 'Context/current-app.context';
import AppContextState from './app-context.state';

const AppContext = createContext<AppContextModel>({} as AppContextModel);
const { Provider } = AppContext;
const reducer = (state: AppContextState, action: DispatchAction) => {
    switch (action.type) {
        case 'SUBSCRIBE':
            return DispatchHandlers.messageSubscribeHandler(state, action);
        case 'SUBSCRIBE_SYNC_UPDATES':
            return DispatchHandlers.syncSubscribeHandler(state, action);
        case 'UNSUBSCRIBE_SYNC_UPDATES':
            return DispatchHandlers.syncUnSubscribeHandler(state, action);
        case 'UNSUBSCRIBE':
            return DispatchHandlers.messageUnSubscribeHandler(state, action);
        case 'ADD_LOCALIZATION_OBJECT':
            return DispatchHandlers.localizationHanlder(state, action);
        case 'PUSH_LOADED_ITEM':
            return DispatchHandlers.pushLoadedItem(state, action);
        case 'SET_HISTORY_SIZE':
            return DispatchHandlers.setHistorySize(state, action);
        case 'SET_THEME_CONFIG':
            return DispatchHandlers.setThemeConfig(state, action);
        case 'SET_AUTH_TOKEN':
            return DispatchHandlers.setAuthToken(state, action);
        case 'SET_LANGUAGE_CODE':
            return DispatchHandlers.setLanguageCode(state, action);
        case 'SET_IsMarvinLoadedInPlatform':
            return DispatchHandlers.setIsMarvinLoadedInPlatform(state, action);
        case 'SET_RecentContactMode':
            return DispatchHandlers.setRecentContactMode(state, action);
        case 'SET_MODAL_STATUS':
            return DispatchHandlers.setModalState(state, action);
        case 'SET_LEAD_OPENED_THROUGH_RC':
            return DispatchHandlers.setLeadOpenedThroughRC(state, action);
        case 'SET_INITIAL_APP_LOADING':
            return DispatchHandlers.setInitialAppLoading(state, action);
        case 'SET_WHATSAPP_ENABLED':
            return DispatchHandlers.setIsWhatsappEnabled(state, action);
        case 'SET_ONGOING_CALL':
            return DispatchHandlers.setOnGoingCall(state, action);
        case 'CLEAR_APP_STATE':
            return new AppContextState();
        default:
            return state;
    }
};
const AppContextProvider = ({ children }: any) => {
    const defaultState = new AppContextState();
    const [state, subsribeForMessages] = useReducer(reducer, defaultState);
    return (
        <Provider
            value={{ state, dispatchToApplicationState: subsribeForMessages }}
        >
            {children}
        </Provider>
    );
};

interface AppContextModel {
    state: AppContextState;
    dispatchToApplicationState: React.Dispatch<DispatchAction>;
}

export interface DispatchAction {
    type: string;
    payload: any;
    localizationObject?: { [key: string]: string };
    historySize?: number;
}

/*
    AppContextProvider: Component, when wrapper around child components, will give access to AppContext to those child components
    AppContext: Defines app context which has app level state
*/

export { AppContextProvider, AppContext };

class DispatchHandlers {
    static setOnGoingCall = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.onGoingCall = action.payload;
        return newState;
    };

    static messageSubscribeHandler = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.MessageSubscribers[action.payload.Key] = action.payload.Func;
        return newState;
    };

    static setHistorySize = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.HistorySize = action.historySize ?? 0;
        return newState;
    };

    static messageUnSubscribeHandler = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        delete newState.MessageSubscribers[action.payload.Key];
        return newState;
    };

    static syncSubscribeHandler = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.SyncStateSubscribers[action.payload.Key] = action.payload.Func;
        return newState;
    };

    static syncUnSubscribeHandler = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        delete newState.SyncStateSubscribers[action.payload.Key];
        return newState;
    };

    static localizationHanlder = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        if (action.localizationObject) {
            const newState = { ...state };
            newState.LocalizationObject = action.localizationObject;
            return newState;
        }
        return state;
    };

    static pushLoadedItem = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        if (action.payload) {
            const newState = { ...state };
            newState.LoadedItems = newState.LoadedItems ?? [];
            newState.LoadedItems.push(action.payload);
            return newState;
        }
        return state;
    };

    static setThemeConfig = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        MXLogger.debug('themeconfig: ', action.payload);
        newState.ThemeConfig = action.payload ?? {};
        return newState;
    };

    static setAuthToken = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        MXLogger.debug('authToken: ', action.payload);
        newState.AuthenticationDetails = action.payload ?? {};
        return newState;
    };

    static setLanguageCode = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        MXLogger.debug('langCode: ', action.payload);
        newState.Language = action.payload ?? '';
        return newState;
    };

    static setModalState = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.ModalState.modalOpened = action.payload.modalOpened ?? false;
        return newState;
    };

    static setIsMarvinLoadedInPlatform = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.isMarvinLoadedInPlatform = action.payload ?? false;
        return newState;
    };

    static setRecentContactMode = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.RecentContactMode = action.payload.mode;
        MXCache.SetCacheItem(
            MXCache.RecentContactModeKey,
            action.payload.currentApp.appType === AppTypeEnum.Platform
                ? ContactBoxMode.Minimized
                : action.payload.mode,
            90000
        );
        return newState;
    };

    static setLeadOpenedThroughRC = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.IsLeadOpenedThroughRC = action.payload;
        return newState;
    };

    static setInitialAppLoading = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        newState.IsInitialAppLoading = action.payload;
        return newState;
    };

    static setIsWhatsappEnabled = (
        state: AppContextState,
        action: DispatchAction
    ): AppContextState => {
        const newState = { ...state };
        console.log('what app context', newState, action.payload);
        newState.isWhatsappEnabled = action.payload;
        return newState;
    };
}
