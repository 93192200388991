import React, { useState, useEffect, Fragment } from 'react';
import './banner.component.css';
import Close from 'Assets/Images/Close.svg';
import MXEventEmitter from 'Services/MXEventEmitter';
import {
    getMobileAuthCookie,
    getPlatformNamefromCookie,
} from 'utils/getMobileCookies';
import MXLogger from 'Common/MXLogger';

export enum BannerState {
    Success = 1,
    Warning = 2,
    Failure = 3,
}

export const BannerComponent = ({
    state,
    content,
    showClose = false,
    timeout = 3000,
    removedAlert,
    isWebView,
    handleMobileRefresh,
}: {
    state: BannerState;
    content: string;
    showClose: boolean;
    timeout: number;
    removedAlert: Function;
    isWebView?: boolean;
    handleMobileRefresh: () => void;
}) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const after = timeout ?? 3000;
        let timeoutRef: NodeJS.Timeout;
        if (visible) {
            timeoutRef = setTimeout(() => {
                close();
            }, after);
        }

        return () => {
            clearTimeout(timeoutRef);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let close = () => {
        setVisible(false);
        if (removedAlert) {
            setTimeout(removedAlert, 500);
        }
    };

    const isRefreshIncluded =
        content && typeof content === 'string' && content.includes('refresh');

    const messageContent =
        isWebView && isRefreshIncluded ? content.split('refresh') : [];

    return (
        <div
            className={`cv-banner-wrapper ${GetClassByState(state)} ${
                !visible ? 'hidden' : ''
            }`}
        >
            <div
                className={`cv-banner-content ${
                    isWebView ? 'cv-banner-content-mobile' : ''
                }`}
            >
                {!isWebView || !isRefreshIncluded ? (
                    <span>{content}</span>
                ) : (
                    messageContent.map((msgContent, index) => (
                        <Fragment key={msgContent}>
                            <span>{msgContent}</span>
                            {index === 0 &&
                                isRefreshIncluded &&
                                messageContent.length > 1 && (
                                    <a href="#" onClick={handleMobileRefresh}>
                                        refresh
                                    </a>
                                )}
                        </Fragment>
                    ))
                )}
            </div>
            {showClose ? (
                <div className="cv-banner-action">
                    <div className="cv-banner-close">
                        <img src={Close} alt="close" onClick={close} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export const BannerTray = ({
    listenTo,
    isWebView,
    setSessionTimeoutModel,
}: {
    listenTo: string[];
    isWebView?: boolean;
    setSessionTimeoutModel?: (flag: boolean) => void;
}) => {
    const [banners, setBanners] = useState<any[]>([]);

    useEffect(() => {
        MXEventEmitter.on('remove', removeNetworkBanner);
        return () => {
            MXEventEmitter.off('remove', removeNetworkBanner);
        };
    }, []);

    useEffect(() => {
        listenTo.forEach((listen) => {
            MXEventEmitter.on(listen, addBanner);
        });
        // MXLogger.debug("Banner component Rendered");
        return function cleanup() {
            // MXLogger.debug(`Banner component dismounted at ${new Date()}`);
            listenTo.forEach((listen) => {
                MXEventEmitter.off(listen, addBanner);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listenTo, banners]);

    const removeNetworkBanner = (payload: any) => {
        if (payload?.endpoint) {
            setBanners((banners) =>
                banners.filter((b) => b.endpoint !== payload.endpoint)
            );
        }
    };

    const removeBanner = (bannerID: number) => {
        MXLogger.debug(
            `Removing banner with ${bannerID} from all banners ${banners} at ${new Date()}`
        );
        setBanners((banners) => banners.filter((b) => b.BannerID !== bannerID));
    };
    let addBanner = (payload: any) => {
        if (payload.ID === 'SESSION_TIMEOUT_BANNER') {
            setSessionTimeoutModel && setSessionTimeoutModel(true);
            return;
        }

        if (payload.Remove && payload.Remove === true) {
            const bannerFound = banners.find((i) => i.BannerID === payload.ID);
            MXLogger.debug(
                `Trying to find ${bannerFound} to remove it at ${new Date()}`
            );
            if (bannerFound) {
                removeBanner(payload.ID);
            }
            return;
        }
        setBanners((banners) => {
            // eslint-disable-next-line  no-param-reassign
            payload.BannerID = payload.ID ?? (new Date() as any) / 1;
            const bannerFound = banners.find((i) => i.BannerID === payload.ID);
            if (bannerFound) {
                return banners;
            }
            return [...banners, payload];
        });
    };

    const handleMobileRefresh = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window?.location?.reload();
    };

    return (
        <div className="cv-banner-tray">
            {banners.map((bannerState) => (
                <BannerComponent
                    content={bannerState.Message}
                    showClose
                    timeout={bannerState.Timeout}
                    state={bannerState.BannerState}
                    key={bannerState.BannerID}
                    removedAlert={() => {
                        removeBanner(bannerState.BannerID);
                    }}
                    isWebView={isWebView}
                    handleMobileRefresh={handleMobileRefresh}
                />
            ))}
        </div>
    );
};

const GetClassByState = (state: BannerState) => {
    switch (state) {
        case BannerState.Failure:
            return 'cv-failure-banner';
        case BannerState.Warning:
            return 'cv-warning-banner';
        default:
            return 'cv-success-banner';
    }
};
