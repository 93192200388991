import ContactMiniModel from './contact-mini.model';
import MessageHistoryItem from './message-history-item.model';

export interface SelectedContactModel extends ContactMiniModel {
    opportunityInstanceId: string;
    Mode: ContactBoxMode;
    SearchMetadata: SearchMetadata | null;
}
export interface SearchMetadata {
    IsMessageSearch: boolean;
    MessageSearched: MessageHistoryItem;
}

export enum ContactBoxMode {
    Minimized = 1,
    Maximized = 2,
}
