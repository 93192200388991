import { AppMode } from 'Common/Enums/metadata.enum';
import RecentContactModel from './recentcontacts.model';
import { SelectedContactModel } from './selected-contact.model';

export default class RecentContactReduxState {
    RecentContacts: RecentContactModel[];

    CarterContact: RecentContactModel;

    SelectedRecentContacts: SelectedContactModel[];

    OpenedRecentContacts: RecentContactModel[];

    isLoaded: boolean;

    appMode?: AppMode;

    constructor(state?: RecentContactReduxState) {
        this.RecentContacts = state ? state.RecentContacts : [];
        this.SelectedRecentContacts = state ? state.SelectedRecentContacts : [];
        this.OpenedRecentContacts = state ? state.OpenedRecentContacts : [];
        this.isLoaded = state ? state.isLoaded : false;
        this.appMode = state ? state.appMode : undefined;
        this.CarterContact = state
            ? state.CarterContact
            : ({} as RecentContactModel);
    }
}
