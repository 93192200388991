import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from 'Context/app.context';
import MXLogger from 'Common/MXLogger';
import { AppMode } from 'Common/Enums/metadata.enum';
import lsqMarvinExternalAppIntegrator from '@lsq-marvin-app-utility/external-app-integrator';
import Spinner from 'Components/Reusable/Spinner/spinner.component';
import {
    AppTypeEnum,
    CurrentAppContext,
    MobileViewEnum,
} from 'Context/current-app.context';
import MXCache from 'Common/CacheManager/MXCache';

import { isMobile } from 'Common/Utils/CheckDevice';
import './auth.component.css';
import { ErrorComponent } from 'Components/Reusable/ErrorBoundary/error-boundary';
import { AppRenderContext } from 'Context/app-render.context';
import { CurrentFilterContext } from 'Context/current-filter.context';
import { handleMobileToSetUserAuthToken } from 'utils/mobileCallbackHandle';
import AuthService from '../../../Services/APIServices/auth.service';

/*
    Any Component wrapped with this AuthComponent will only be rendered if auth is done
*/
export default ({ children }: any) => {
    const [auth, setAuth] = useState(false);
    const [isAuthLoading, setIsAuthLoading] = useState<boolean | null>(null);
    const [IsConverseOnRestrictedPage, setIsConverseOnRestrictedPage] =
        useState(false);
    const [mode, setMode] = useState<AppMode>();
    const currentApp = useContext(CurrentAppContext);
    const { state: appState, dispatchToApplicationState } =
        useContext(AppContext);
    const { dispatch: dispatchFilterData } = useContext(CurrentFilterContext);
    const { allowAppRender, setAllowAppRender } = useContext(AppRenderContext);

    useEffect(() => {
        if (currentApp.appType) {
            if (
                currentApp.appType === AppTypeEnum.Fullscreen &&
                currentApp.isOpenInsideIframe
            ) {
                setMode(AppMode.MarvinFullScreen);
            } else if (currentApp.appType === AppTypeEnum.Fullscreen) {
                setMode(AppMode.FullScreen);
            } else if (currentApp.mobileView === MobileViewEnum.WebView) {
                setMode(AppMode.LsqMobile);
            } else if (currentApp.appType === AppTypeEnum.Marvin) {
                if (isMobile()) {
                    setMode(AppMode.MarvinMobile);
                } else {
                    setMode(AppMode.MarvinWidget);
                }
            } else {
                setMode(AppMode.Widget);
            }
        }
    }, [currentApp]);

    useEffect(() => {
        if (
            mode &&
            [
                AppMode.MarvinMobile,
                AppMode.MarvinWidget,
                AppMode.MarvinFullScreen,
            ].includes(mode)
        ) {
            lsqMarvinExternalAppIntegrator.context.get({
                callBackFn: (data: any) => {
                    console.log({ data });
                    dispatchToApplicationState({
                        type: 'SET_IsMarvinLoadedInPlatform',
                        payload: data.isMarvinLoadedInPlatform,
                    });
                    dispatchToApplicationState({
                        type: 'SET_AUTH_TOKEN',
                        payload: data.authenticationDetails,
                    });
                    dispatchToApplicationState({
                        type: 'SET_THEME_CONFIG',
                        payload: data.theme.config,
                    });
                    dispatchToApplicationState({
                        type: 'SET_LANGUAGE_CODE',
                        payload: data.language,
                    });
                },
            });
        }
    }, [mode]);

    useEffect(() => {
        console.log({ appState });
        if (!appState.HistorySize) setAuth(false);
    }, [appState.HistorySize]);

    useEffect(() => {
        console.log({ auth });
        if (auth) setIsAuthLoading(false);
    }, [auth]);

    useEffect(() => {
        console.log({ allowAppRender });
    }, [allowAppRender]);

    useEffect(() => {
        MXCache.SetCacheItem(MXCache.AppModeKey, mode, 600);
        if (mode && [AppMode.FullScreen].includes(mode)) {
            const url = new URL(window.location.href);
            if (url.searchParams.has('Token')) {
                const auth = {
                    Token: url.searchParams.get('Token'),
                    ExpiresOn: url.searchParams.get('ExpiresOn'),
                };
                const stringifyAuth = JSON.stringify(auth);
                MXCache.SetCacheItem(MXCache.AuthKey, stringifyAuth, 480);
            }
        }
        if (
            allowAppRender &&
            mode &&
            [
                AppMode.MarvinMobile,
                AppMode.MarvinWidget,
                AppMode.MarvinFullScreen,
            ].includes(mode) &&
            appState?.AuthenticationDetails?.token
        ) {
            authorizeMarvin(mode, appState?.AuthenticationDetails?.token);
            setAllowAppRender(false);
        } else if (
            allowAppRender &&
            mode &&
            [AppMode.Widget, AppMode.FullScreen, AppMode.LsqMobile].includes(
                mode
            )
        ) {
            if (
                window.location.pathname
                    .toLowerCase()
                    .includes('/a/servicecloud')
            ) {
                console.log('Converse not allowed');
                setIsConverseOnRestrictedPage(true);
            } else if (
                window.location.pathname
                    .toLowerCase()
                    .includes('/s/customer360')
            ) {
                setIsConverseOnRestrictedPage(true);
            } else {
                authorize(mode);
            }
            setAllowAppRender(false);
        }
        if (currentApp.appType !== AppTypeEnum.Platform) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any)?.newrelic?.setCustomAttribute(
                'appType',
                'CONVERSE',
                true
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, appState?.AuthenticationDetails?.token, allowAppRender]);

    const authorize = async (mode: AppMode) => {
        try {
            setIsAuthLoading(true);
            if (await new AuthService(mode).isAuthorized()) {
                MXLogger.debug('User Is Authroized');
                dispatchToApplicationState({
                    type: 'PUSH_LOADED_ITEM',
                    payload: 'Auth',
                });
                setAuth(true);
            } else {
                console.log('trouble logging in');
                setIsAuthLoading(false);
            }
        } catch (e) {
            setIsAuthLoading(false);
        }
    };

    const authorizeMarvin = async (
        mode: AppMode,
        authorizationToken: string
    ) => {
        setIsAuthLoading(true);
        const authService = new AuthService(mode, authorizationToken);
        if (await authService.isAuthorized()) {
            MXLogger.debug('User Is Authroized');
            dispatchToApplicationState({
                type: 'PUSH_LOADED_ITEM',
                payload: 'Auth',
            });
            setAuth(true);
        } else {
            setIsAuthLoading(false);
            MXLogger.debug('User Is not Authroized');
            (window as any).parent.postMessage('auth_failed', '*');
        }
        setIsAuthLoading(false);
        lsqMarvinExternalAppIntegrator.eventSubscriber.onSignOut({
            callBackFn: (data) => {
                authService.Logout();
            },
        });
    };

    const handleMobileRefresh = () => {
        MXCache.Clear();
        handleMobileToSetUserAuthToken({}, true);
    };

    (window as any).clearAppStateFromConsole = () => {
        dispatchToApplicationState({ type: 'CLEAR_APP_STATE', payload: '' });
    };

    return (
        <>
            {auth ? (
                children
            ) : isAuthLoading && mode === AppMode.LsqMobile ? (
                <Spinner
                    className="cv-auth-display-center"
                    altText="auth is loading"
                />
            ) : mode === AppMode.LsqMobile ? (
                <div className="cv-auth-display-center cv-auth-error-message">
                    {' '}
                    <span> Something went wrong </span>
                    <button onClick={handleMobileRefresh}>Try again</button>
                </div>
            ) : (
                isAuthLoading === false &&
                (mode === AppMode.Widget || mode === AppMode.MarvinWidget) &&
                (!IsConverseOnRestrictedPage ? (
                    <ErrorComponent appMode={currentApp.appType} />
                ) : (
                    ''
                ))
            )}
        </>
    );
};
