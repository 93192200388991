import React from 'react';
import SmallCircleLoader from 'Assets/Images/SmallCircleLoader.svg';

const Spinner = ({
    altText,
    className,
}: {
    altText?: string;
    className?: string;
}) => (
    <div className={className}>
        <img src={SmallCircleLoader} alt={altText} />
    </div>
);

export default Spinner;
