import React, { createContext, useState } from 'react';

export const AppRenderContext = createContext<any>({
    allowAppRender: true,
    setAllowAppRender: () => {},
});

export default function AppRenderProvider({ children }: { children: any }) {
    const [allowAppRender, setAllowAppRender] = useState(true);

    return (
        <AppRenderContext.Provider
            value={{
                allowAppRender,
                setAllowAppRender,
            }}
        >
            {children}
        </AppRenderContext.Provider>
    );
}
