import React, { useContext, useEffect, useState } from 'react';
import MXCache from 'Common/CacheManager/MXCache';
import './error-boundary.css';
import { AppTypeEnum } from 'Context/current-app.context';
import { IoReload } from 'react-icons/io5';
import { ContactBoxMode } from 'Common/Models/selected-contact.model';
import { isMobile } from 'Common/Utils/CheckDevice';
import { throttle } from 'lodash';
import DashboardServices from 'Services/APIServices/dashboard.service';
import { AppRenderContext } from 'Context/app-render.context';
import { AuthResponse } from 'Common/Models/authresponse.model';

export default class ErrorBoundary extends React.Component<
    { currentApp: AppTypeEnum; children: React.ReactNode },
    { hasError: any }
> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        const app = this.props.currentApp;
        console.log({ app });
        console.log(error, errorInfo);

        if (app === AppTypeEnum.Platform) {
            const user = MXCache.GetCacheItem(MXCache.UserDetailKey);
            new DashboardServices().postNewRelicLog({
                appMode: app,
                orgId: user?.orgCode ?? '',
                userId: user?.userId ?? '',
                eventType: 'error',
                widgetBreak: true,
                error: JSON.stringify(error),
                failedApi: '',
                failedStatusCode: '',
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any)?.newrelic?.noticeError(error);
        }

        // just to avoid infinite app reload I am adding a cache of 3 minutes

        const isAppAlreadyRefreshed = MXCache.GetCacheItem(
            MXCache.IsAppRefreshed
        );
        if (!isAppAlreadyRefreshed) {
            MXCache.Clear();
            MXCache.SetCacheItem(MXCache.IsAppRefreshed, true, 3);
            window.location.reload();
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // clear MXCache and ask to reload
            const user = MXCache.GetCacheItem(MXCache.CopyUserDetailKey);
            MXCache.Clear();
            if (this.props.currentApp === AppTypeEnum.Fullscreen) {
                return (
                    <div className="cv-fs-fullpage cv-flex-center cv-gap-12">
                        <div style={{ textAlign: 'center' }}>
                            Oops! We’re having trouble loading the chat. Please
                            try reloading the page.
                        </div>
                        <button
                            className="cv-error-try-again-btn"
                            onClick={() => {
                                if (
                                    this.props.currentApp ===
                                    AppTypeEnum.Platform
                                ) {
                                    new DashboardServices().postNewRelicLog({
                                        appMode: this.props.currentApp,
                                        orgId: user?.orgCode ?? '',
                                        userId: user?.userId ?? '',
                                        eventType: 'reload',
                                        widgetBreak: false,
                                        error: '',
                                        failedApi: '',
                                        failedStatusCode: '',
                                    });
                                } else {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    (window as any)?.newrelic?.addPageAction(
                                        'Reload Click'
                                    );
                                }
                                window.location.reload();
                            }}
                        >
                            <IoReload color="#fff" />
                            reload
                        </button>
                    </div>
                );
            }
            return <ErrorComponent appMode={this.props.currentApp} />;
        }

        return this.props.children;
    }
}

export function ErrorComponent({ appMode }: { appMode: AppTypeEnum }) {
    const [minimized, setMinimized] = useState(true);
    const { setAllowAppRender } = useContext(AppRenderContext);
    const throttleToggle = () => {
        console.log('throttleToggle');
        throttle(() => setMinimized((prev) => !prev), 1500);
    };

    useEffect(() => {
        if (window.self !== window.parent && window.parent && !isMobile()) {
            // running inside iframe
            if (appMode === AppTypeEnum.Marvin) {
                window.parent.postMessage('show-error-component', '*');
            }
        }
        const rcmode =
            MXCache.GetCacheItem(MXCache.RecentContactModeKey) ??
            ContactBoxMode.Minimized;
        const isMinimized = rcmode === ContactBoxMode.Minimized;
        setMinimized(isMinimized);
    }, []);

    useEffect(() => {
        if (!isMobile()) {
            (window as any).parent.postMessage(
                {
                    message: 'toggle-iframe-mode',
                    value: minimized
                        ? ContactBoxMode.Minimized
                        : ContactBoxMode.Maximized,
                },
                '*'
            );
        }
        return () =>
            (window as any).parent.postMessage(
                {
                    message: 'toggle-iframe-mode',
                    value: MXCache.GetCacheItem(MXCache.RecentContactModeKey),
                },
                '*'
            );
    }, [minimized]);

    return (
        <div
            className={`cv-widgets-holder ${
                appMode === AppTypeEnum.Marvin && !isMobile()
                    ? 'cv-error-marvin'
                    : 'cv-error-platform'
            }`}
            style={{ right: '80px' }}
        >
            <div
                className={`cv-recent-contacts-widget-container  ${
                    minimized ? 'cv-header-error-page-minimized' : ''
                }`}
            >
                <div
                    className="cv-rc-widget-header"
                    onClick={() => setMinimized((prev) => !prev)}
                >
                    <div
                        className={`cv-rc-widget-header-wrapper ${
                            minimized ? 'cv-error-rc-header-minimized' : ''
                        }`}
                    >
                        <div className="cv-rc-widget-header-title">
                            <span className="cv-rc-widget-header-name">
                                Converse - Chat
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={`cv-rc-widget-body cv-flex-center cv-gap-12 ${
                        minimized ? 'cv-error-page-minimized' : ''
                    }`}
                >
                    {minimized ? (
                        ''
                    ) : (
                        <>
                            <div style={{ textAlign: 'center' }}>
                                Oops! We’re having trouble loading the chat.
                                Please try reloading the page.
                            </div>
                            <button
                                className="cv-error-try-again-btn"
                                onClick={() => {
                                    if (appMode === AppTypeEnum.Platform) {
                                        const user = MXCache.GetCacheItem(
                                            MXCache.CopyUserDetailKey
                                        );
                                        new DashboardServices().postNewRelicLog(
                                            {
                                                appMode,
                                                orgId: user?.orgCode ?? '',
                                                userId: user?.userId ?? '',
                                                eventType: 'reload',
                                                widgetBreak: false,
                                                error: '',
                                                failedApi: '',
                                                failedStatusCode: '',
                                            }
                                        );
                                        setAllowAppRender(true);
                                    } else {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                        (
                                            window as any
                                        )?.newrelic?.addPageAction(
                                            'Reload Click'
                                        );
                                        window.location.reload();
                                    }
                                }}
                            >
                                <IoReload color="#fff" />
                                reload
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
