import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { AuthResponse } from 'Common/Models/authresponse.model';
import MXLogger from 'Common/MXLogger';
import MXCache from 'Common/CacheManager/MXCache';
import axiosRetry from 'axios-retry';
import { BannerState } from 'Components/Reusable/Banner/banner.component';
import { clear, createStore } from 'idb-keyval';
import { getOSType } from 'Common/Utils/CheckDevice';
import { AppMode } from 'Common/Enums/metadata.enum';
import lsqMarvinExternalAppIntegrator from '@lsq-marvin-app-utility/external-app-integrator';
import { handleMobileToSetUserAuthToken } from 'utils/mobileCallbackHandle';
import MXEventEmitter from './MXEventEmitter';

const checkAuthorizedfailed = (error: any, mode: AppMode) =>
    error?.config?.url === 'authorize' ||
    (error?.config?.url === 'isauthorized' &&
        [AppMode.MarvinFullScreen, AppMode.FullScreen].includes(mode));

const checkIsNewRelicLogApi = (error: any) =>
    error?.config?.url === 'instrument/apm' ||
    error?.config?.url === '/instrument/apm';

export enum RequestTypeEnum {
    Contacts = 1,
    ConnectorConfig = 2,
    MessageHistory = 3,
    LeadDetails = 4,
    SessionTimeout = 5,
}

const getBannerMessage = (requestType?: RequestTypeEnum | undefined) => {
    switch (requestType) {
        case RequestTypeEnum.Contacts:
            return 'We are unable to load the chats right now. Please refresh the page to retry.';
        case RequestTypeEnum.ConnectorConfig:
            return 'Something went wrong. Please refresh the page to retry.';
        case RequestTypeEnum.LeadDetails:
            return 'We are unable to load the chat history at the moment. Please refresh the page to retry.';
        case RequestTypeEnum.MessageHistory:
            return 'We are unable to load the chat right now. Please refresh the page to retry.';
        case RequestTypeEnum.SessionTimeout:
            return 'Session timeout';
        default:
            return 'Something went wrong, Please refresh the page or Contact Us if the problem persists.';
    }
};

const appModeDecider = () => {
    const mode = MXCache.GetCacheItem(MXCache.AppModeKey);
    // if fullscreen, check if open in iframe, bcoz fullscreen and MarvinFullscreen has same domain
    if ([2, 3].includes(mode)) {
        if (window.parent === window.self) {
            return 2;
        }
        return 3;
    }
    return mode;
};

export default class API {
    instance: AxiosInstance;

    static redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || '';

    static marvinRedirectUrl =
        process.env.REACT_APP_AUTH_REDIRECT_URL_MARVIN || '';

    static swliteRedirectUrl =
        process.env.REACT_APP_AUTH_REDIRECT_URL_SWLITE || '';

    static disableRedirect = process.env.REACT_APP_DISABLE_REDIRECT || '';

    static osType = getOSType() || 'unknown';

    static appModeStatic = appModeDecider();

    appMode: number;

    Converse_KeyVal_Store = createStore(
        'Converse_Log',
        'Converse_KeyVal_Store'
    );

    constructor(
        baseURL: string,
        isGuardedRequest = true,
        shouldInterceptResponse = true,
        requestType?: RequestTypeEnum | undefined
    ) {
        this.appMode = API.appModeStatic ? API.appModeStatic : appModeDecider();

        this.instance = Axios.create({
            baseURL,
        });
        axiosRetry(this.instance, {
            retries: Number(process.env.REACT_APP_MAX_API_RETRIES) || 5,
            retryDelay: () =>
                Number(process.env.REACT_APP_API_RETRY_COOLDOWN_TIME) || 5000,
            onRetry(retryCount, error) {
                if (
                    retryCount ===
                    (Number(process.env.REACT_APP_MAX_API_RETRIES) || 5) &&
                    error
                ) {
                    // handle last retry
                    MXEventEmitter.emit('APPLICATION_LEVEL_BANNER', {
                        Message: getBannerMessage(requestType),
                        endpoint: error?.config?.url?.split('?')[0],
                        BannerState: BannerState.Failure,
                        Timeout: 18000000,
                        ID: 'CONNECTION_BANNER',
                    });
                }
            },
        });
        if (isGuardedRequest) {
            this.AddRequestInterceptor();
        }
        if (shouldInterceptResponse) {
            this.AddResponseInterceptors();
        }
    }

    protected Responsify<T>(Response: AxiosResponse) {
        if (Response && Response.status === 200 && Response.data) {
            return Response.data as T;
        }
        if (Response && Response.status !== 413 && Response.status >= 400) {
            // TODO: ReEvaluate logging out on status code greater than 400, 413 added for quick fix for Content Too large
            MXLogger.info('Logout from responsify');
            console.log('Logout from responsify');
            this.Logout();
        }
        return {} as T;
    }

    async postNRLog(logObject: any) {
        const inst = Axios.create({
            baseURL: process.env.REACT_APP_INSIGHTS_BASE_URL || '',
        });
        const authKey = JSON.parse(
            MXCache.GetCacheItem(MXCache.AuthKey) ?? null
        );
        const token = authKey?.Token;
        if (token) {
            return this.Responsify<any>(
                await inst.post('instrument/apm', logObject, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Token: token,
                    },
                })
            );
        }
        console.log('token is empty');
    }

    private AddRequestInterceptor = () => {
        const $this = this;
        const authToken = API.GetAuthToken();
        this.instance.interceptors.request.use(
            (requestConfig) => {
                if (requestConfig?.headers) {
                    requestConfig.headers.APP_MODE = `${this.appMode}`;
                    requestConfig.headers.OS_TYPE = `${API.osType}`;
                    if (authToken) {
                        requestConfig.headers.Authorization = `Bearer ${authToken.Token}`;
                        requestConfig.headers.token = authToken.Token;
                    }
                } else {
                    MXLogger.info('Logout from request interceptor');
                    $this.Logout();
                }
                return requestConfig;
            },
            (error) => Promise.reject(error)
        );
    };

    private AddResponseInterceptors = () => {
        const $this = this;
        this.instance.interceptors.response.use(
            (response) => {
                MXEventEmitter.emit('remove', {
                    endpoint: response?.config?.url?.split('?')[0] ?? '',
                    BannerState: BannerState.Failure,
                    Timeout: 18000000,
                    ID: 'CONNECTION_BANNER',
                });
                return response;
            },
            (error) => {
                MXLogger.info(error);
                // don't logout for new relic api failure as it just a log api and
                // failure of this API should not affect the functionality of the application
                if (
                    error &&
                    error.response &&
                    error.response.status === 401 &&
                    !checkIsNewRelicLogApi(error)
                ) {
                    const { appMode } = this;
                    if (appMode === AppMode.Widget) {
                        const user = MXCache.GetCacheItem(
                            MXCache.UserDetailKey
                        );

                        this.postNRLog({
                            appMode,
                            appType: '',
                            orgId: user?.orgCode ?? '',
                            userId: user?.userId ?? '',
                            eventType: 'error',
                            widgetBreak: false,
                            error: JSON.stringify(error),
                            endpoint: error?.config?.url ?? '',
                            status: error.response?.status ?? '',
                            failedApi: '',
                            failedStatusCode: '',
                        });
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        (window as any)?.newrelic?.noticeError(error, {
                            endpoint: error?.config?.url ?? '',
                            status: error.response?.status ?? '',
                        });
                    }
                    if (
                        this.appMode === AppMode.LsqMobile ||
                        checkAuthorizedfailed(error, this.appMode)
                    ) {
                        $this.Logout();
                    } else if (
                        [
                            AppMode.Widget,
                            AppMode.MarvinMobile,
                            AppMode.MarvinWidget,
                        ].includes(this.appMode)
                    ) {
                        const isAppAlreadyRefreshed = MXCache.GetCacheItem(
                            MXCache.IsAppRefreshed
                        );
                        // just to avoid infinite app reload I am adding a cache of 3 minutes to recall the app reload if any 401 error throws in api
                        if (isAppAlreadyRefreshed) {
                            $this.Logout();
                            // emit event to show ErrorComponent
                        } else {
                            MXCache.Clear();
                            MXCache.SetCacheItem(
                                MXCache.IsAppRefreshed,
                                true,
                                3
                            );
                            window.location.reload();
                        }
                    } else {
                        MXEventEmitter.emit('APPLICATION_LEVEL_BANNER', {
                            Message: getBannerMessage(
                                RequestTypeEnum.SessionTimeout
                            ),
                            BannerState: BannerState.Failure,
                            Timeout: 18000000,
                            ID: 'SESSION_TIMEOUT_BANNER',
                        });
                    }
                } else if (!error.response) {
                    // this else if condition only to capture the error response which is undefined
                    if (this.appMode === AppMode.Widget) {
                        const user = MXCache.GetCacheItem(
                            MXCache.UserDetailKey
                        );
                        // don't log for new relic api failure
                        if (!checkIsNewRelicLogApi(error)) {
                            this.postNRLog({
                                appMode: this.appMode,
                                appType: '',
                                orgId: user?.orgCode ?? '',
                                userId: user?.userId ?? '',
                                eventType: 'error',
                                widgetBreak: false,
                                endpoint: error?.config?.url ?? '',
                                error: JSON.stringify(error),
                                status: error.response?.status ?? '',
                                failedApi: '',
                                failedStatusCode: '',
                            });
                        }
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        (window as any)?.newrelic?.noticeError(error, {
                            endpoint: error?.config?.url ?? '',
                            status: error.response?.status ?? '',
                        });
                    }
                }

                return Promise.reject(error);
            }
        );
    };

    protected static GetAuthToken(): AuthResponse | null {
        const authToken = MXCache.GetCacheItem(MXCache.AuthKey) || '';
        if (authToken && authToken.length > 0) {
            return JSON.parse(authToken) as AuthResponse;
        }
        return null;
    }

    protected static RemoveAuthToken() {
        MXCache.RemoveOneItem(MXCache.AuthKey);
    }

    protected static SetAuthToken(authResponse: AuthResponse) {
        MXCache.SetCacheItem(
            MXCache.AuthKey,
            JSON.stringify(authResponse),
            480
        );
        MXCache.SetCacheItem(
            MXCache.CopyAuthKey,
            JSON.stringify(authResponse),
            480
        );
        (window as any).MXNotificationClient.updateAuthToken(
            JSON.stringify(authResponse)
        );
    }

    LogoutApi = async (authToken: AuthResponse | null) => {
        if (authToken) {
            const inst = Axios.create({
                baseURL: process.env.REACT_APP_API_SERVICE_BASE_URL || '',
            });
            await inst.get('auth/user/logout', {
                headers: {
                    Authorization: `Bearer ${authToken.Token}`,
                    token: authToken.Token,
                },
            });
        }
    };

    Logout(redirectUrl: string | null = null, cb: Function | null = null) {
        console.log('Logout from platform called');
        if ((window as any).MXNotificationClient?.closeSharedWorker) {
            console.log('Closing shared worker');
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any).MXNotificationClient?.closeSharedWorker();
        }
        let redirectTo =
            this.appMode === AppMode.MarvinFullScreen
                ? API.marvinRedirectUrl
                : API.redirectUrl;
        if (redirectUrl && redirectUrl.length) {
            redirectTo = redirectUrl;
        }
        const authToken = API.GetAuthToken();
        MXCache.Clear();
        if (this.appMode === AppMode.LsqMobile) {
            // this method is to reset the user authorization information stored in mobile device cache, this cache we are storing at the first logic,
            // and using the same cache when we trying to open after that, so when we faced 401 in iur API, we are here just
            // clearing it from mobile cache.
            handleMobileToSetUserAuthToken({}, true);
        }
        clear(this.Converse_KeyVal_Store);
        this.LogoutApi(authToken)
            .then(() => {
                if (API.disableRedirect !== '1') {
                    if (this.appMode === AppMode.MarvinFullScreen) {
                        try {
                            lsqMarvinExternalAppIntegrator.context.get({
                                callBackFn: (data: any) => {
                                    const redirectMarvinOrSWLite =
                                        data?.isSWLite
                                            ? API.swliteRedirectUrl
                                            : API.marvinRedirectUrl;
                                    window.open(redirectMarvinOrSWLite, '_top');
                                },
                            });
                        } catch (err) {
                            console.log('App integrator object not available');
                        }
                    } else window.open(redirectTo, '_self');
                }
            })
            .catch((e) => {
                MXLogger.error(new Error(e));
            })
            .finally(() => {
                MXCache.Clear();
                if (cb && typeof cb === 'function') {
                    try {
                        cb(true);
                    } catch (error) {
                        MXLogger.error(new Error(error));
                    }
                }
            });
    }
}
