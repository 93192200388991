import MXCache from 'Common/CacheManager/MXCache';
import API from '../API';

export default class LocalizationService extends API {
    static baseUrl = process.env.REACT_APP_LOCALIZATION_URL || '';

    static apiBaseUrl =
        `${process.env.REACT_APP_API_SERVICE_BASE_URL}auth/` || '';

    static clientServiceUrl = process.env.REACT_APP_CLIENT_SERVICE_URL || '';

    constructor() {
        super(LocalizationService.baseUrl, false, false);
    }

    async getLocalizedObject(fileName: string): Promise<any> {
        const axiosResponse = await this.instance.get(fileName);
        if (axiosResponse.status < 300) return axiosResponse.data;
        return undefined;
    }

    async getLocalizationFileName(): Promise<string> {
        let localization = MXCache.GetCacheItem(MXCache.Localization) as string;
        if (localization) {
            return localization;
        }
        localization = this.Responsify<string>(
            await new API(
                LocalizationService.apiBaseUrl,
                true,
                true
            ).instance.get('user/localization-filename', {
                withCredentials: true,
            })
        );
        if (!localization) {
            console.error(new Error('Unable to fetch localization string'));
        } else {
            MXCache.SetCacheItem(MXCache.Localization, localization, 480);
        }
        return localization;
    }
}
