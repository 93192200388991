declare const window: any;

// async
const telemetry = (
    moduleName: string,
    event: string,
    data: { [key: string]: string }
) => {
    try {
        const { pendo } = window;
        if (pendo && pendo.isReady && pendo.isReady() && pendo.track) {
            pendo.track(`Converse_${moduleName}_${event}`, data);
        }
    } catch (error) {
        console.log('Error in telemetry :', error);
    }
};
const preparePendoOptions = (externalData: any): any | null => {
    const userDetails = externalData?.userDetails;
    if (!userDetails) return null;
    return `{
      orgCode: '${userDetails.orgCode || ''}',
      organizationName: '${userDetails.displayName || ''}',
      customerType: '${userDetails.customerType || ''}',
      tenantIndustry: '${userDetails.industry || ''}',
      tenantSubIndustry: '${userDetails.subIndustry || ''}',
      tenantBusinessType: '${userDetails.businessType || ''}',
      accountPlan: '${userDetails.plan || ''}',
      regionId: '${userDetails.regionId || ''}',
      clusterId: '${userDetails.clusterID || ''}',
      tenantRenewalDate: '${userDetails.renewalDate || ''}',
      creationDate: '${userDetails.createdOn || ''}',
      accountTimeZoneValue: '${userDetails.accountTimeZone || ''}',
      userRegionId: '${userDetails.regionId || ''}',
      userClusterId: '${userDetails.clusterID || ''}',
      userEmailAddress: '${userDetails.email || ''}',
      userRole: '${userDetails.role || ''}',
      userId: '${userDetails.id || ''}',
      userCreationDate: '${userDetails.createdOn || ''}',
      isSystemUser: '${userDetails.isDefaultOwner ? 'Yes' : 'No'}',
      isAgency: '0',
      apiKey: '2c38642a-1d45-4700-5b16-e4490072a8c9',
      loginAppUrl: '${window.location.origin}',
      appType: 'Converse',
    }`;
};
const getPendoScriptContent = (externalData: any): string => `
      (function () {
          try {
            const pendoOptions = ${preparePendoOptions(externalData)};
            const weekday = [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ];
  
            const currentDate = new Date();
            (function (p, e, n, d, o) {
              var v, w, x, y, z;
              o = p[d] = p[d] || {};
              o._q = o._q || [];
              v = [
                'initialize',
                'identify',
                'updateOptions',
                'pageLoad',
                'track',
              ];
              for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                  o[m] =
                    o[m] ||
                    function () {
                      o._q[m === v[0] ? 'unshift' : 'push'](
                        [m].concat([].slice.call(arguments, 0)),
                      );
                    };
                })(v[w]);
              y = e.createElement(n);
              y.async = !0;
              y.src =
                'https://cdn.pendo.io/agent/static/' +
                pendoOptions.apiKey +
                '/pendo.js';
              z = e.getElementsByTagName(n)[0];
              z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo');
  
            // Call this whenever information about your visitors becomes available
            // Please use Strings, Numbers, or Bools for value types.
            pendo.initialize({
              visitor: {
                id: pendoOptions?.userId,
                email: pendoOptions?.userEmailAddress,
                role: pendoOptions?.userRole,
                creationDate: pendoOptions?.userCreationDate,
                isSupportUser: pendoOptions?.isSystemUser,
                newLogin:
                  document.referrer.trim() != '' &&
                  document.referrer.includes(pendoOptions.loginAppUrl) &&
                  performance.navigation.type !=
                    performance.navigation.TYPE_RELOAD,
                weekday: weekday[currentDate.getDay()],
                designation: pendoOptions?.userDesignation,
                appType: pendoOptions?.appType,
              },
  
              account: {
                id: pendoOptions?.orgCode,
                name: pendoOptions?.organizationName,
                plan: pendoOptions?.accountPlan,
                customerType: pendoOptions?.customerType,
                creationDate: pendoOptions?.accountStartingDate,
                businessType: pendoOptions?.tenantBusinessType,
                industry: pendoOptions?.tenantIndustry,
                subIndustry: pendoOptions?.tenantSubIndustry,
                renewalDate: pendoOptions?.tenantRenewalDate,
                city: pendoOptions?.tenantCity,
                country: pendoOptions?.tenantCountry,
                successOwner: pendoOptions?.tenantSuccessOwner,
                accountOwner: pendoOptions?.tenantAccountOwner,
                isAgency: pendoOptions?.isAgency,
                timeZone: pendoOptions?.accountTimeZoneValue,
                billingOption: pendoOptions?.tenantBillingOption,
                regionId: pendoOptions?.userRegionId,
                clusterId: pendoOptions?.userClusterId,
              },
            });
          } catch (e) {
            console.log(e.message);
          }
        })();
      `;

export const createPendoScriptElement = (): HTMLScriptElement | null => {
    const userDetails = JSON.parse(
        localStorage.getItem('LSQ_CONVERSE_UserFields') ?? '{}'
    )?.value;
    const externalData = {
        userDetails,
    };
    try {
        const scriptElement = document.createElement('script');
        scriptElement.id = 'lsq-converse-admin-pendo-script-id';
        scriptElement.async = true;
        scriptElement.crossOrigin = 'anonymous';
        scriptElement.textContent = getPendoScriptContent(externalData);
        return scriptElement;
    } catch (ex) {
        console.log('Exception in createPendoScriptElement :', ex);
    }
    return null;
};

export const logTelemetryData = (
    event: string,
    data: Record<string, string>
): void => {
    telemetry('PENDO_AGENT_UI', event, data);
};
