import React, { useContext, useState, useEffect } from 'react';

import { AppContext } from 'Context/app.context';

import FullScreenLoaderComponent from 'Components/Reusable/Loaders/FullScreenLoader/fullscreen-loader.component';

const RenderLoader = () => {
    const { state: appState } = useContext(AppContext);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const shouldDisplayContent = (): boolean => {
            if (appState && appState.LoadedItems) {
                const res =
                    appState.LoadedItems.indexOf('User') !== -1 &&
                    appState.LoadedItems.indexOf('Auth') !== -1 &&
                    appState.LoadedItems.indexOf('Localization') !== -1;

                return res;
            }
            return false;
        };
        if (shouldDisplayContent()) {
            setShowLoader(false);
        }
    }, [appState, appState.LoadedItems]);

    if (showLoader) {
        return <FullScreenLoaderComponent />;
    }
    return null;
};

export default RenderLoader;
