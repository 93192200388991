import { createContext } from 'react';

export enum AppTypeEnum {
    Marvin = 'marvin',
    Platform = 'platform',
    Fullscreen = 'fullscreen',
    None = '',
}

export enum MobileViewEnum {
    Iframe = 'iframe',
    WebView = 'web-view',
    None = '',
}

export interface CurrentAppModel {
    appType: AppTypeEnum;
    mobileView: MobileViewEnum;
    isMobile: boolean;
    isOpenInsideIframe?: boolean;
}

export const CurrentAppContext = createContext<CurrentAppModel>(
    {} as CurrentAppModel
);
