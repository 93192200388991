import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'Context/app.context';
import LocalizationService from 'Services/APIServices/localization.service';
import MXLogger from 'Common/MXLogger';
import lsqMarvinExternalAppIntegrator from '@lsq-marvin-app-utility/external-app-integrator';
import { AppTypeEnum, CurrentAppContext } from 'Context/current-app.context';

export default ({ children }: any) => {
    const [isLoaded, setLoaded] = useState(false);
    const { state: appState, dispatchToApplicationState } =
        useContext(AppContext);
    const currentApp = useContext(CurrentAppContext);

    const handleLanguageChangeSubscription = () => {
        lsqMarvinExternalAppIntegrator.eventSubscriber.onLanguageChange({
            callBackFn: (langCode: any) => {
                dispatchToApplicationState({
                    type: 'SET_LANGUAGE_CODE',
                    payload: langCode,
                });
            },
        });
    };

    useEffect(() => {
        currentApp.appType === AppTypeEnum.Marvin
            ? handleLanguageChangeSubscription()
            : loadLocalization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        currentApp.appType === AppTypeEnum.Marvin && loadLocalization(); // runs after language set by marvin context
        // Align Converse according to language direction
        (window as any).parent.postMessage(
            {
                message: 'lang-alignment',
                value: appState.Language.direction,
            },
            '*'
        );
    }, [appState?.Language.language]);

    let loadLocalization = async () => {
        let LocalizationObject: { [key: string]: string } = {};
        const localizationService = new LocalizationService();
        try {
            const fileName = appState?.Language.language
                ? getFileName(appState.Language.language) // for lang change in Marvin
                : await localizationService.getLocalizationFileName();
            if (
                fileName?.length &&
                fileName === 'converse-localization-en-US.json'
            ) {
                MXLogger.info('not fetching localization file for en-us');
            } else if (fileName?.length) {
                LocalizationObject =
                    await localizationService.getLocalizedObject(fileName);
            } else {
                MXLogger.info('Localization cookie might not be present');
            }
        } catch (e) {
            MXLogger.error(new Error(`localization object not found${e}`));
        }
        dispatchToApplicationState({
            type: 'PUSH_LOADED_ITEM',
            payload: 'Localization',
        });
        dispatchToApplicationState({
            type: 'ADD_LOCALIZATION_OBJECT',
            payload: null,
            localizationObject: LocalizationObject ?? {},
        });
        setLoaded(true);
    };

    const getFileName = (langCode: string) => {
        switch (langCode) {
            case 'en':
                return 'converse-localization-en-US.json';
            case 'la':
                return 'converse-localization-es-ES.json';
            case 'hi':
                return 'converse-localization-hi-IN.json';
            case 'id':
                return 'converse-localization-id-ID.json';
            case 'br':
                return 'converse-localization-pt-BR.json';
            case 'vi':
                return 'converse-localization-vi-VN.json';
            default:
                return 'converse-localization-en-US.json';
        }
    };

    return <>{children}</>;
};
