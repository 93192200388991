import {
    DashboardChatCounts,
    UnansweredChatCount,
    TotalMessageCounts,
    UnassignedChatCount,
    AgentPerformanceReportTypes,
    ChatStatusReport,
    AverageSlaReport,
    ChatInitiatedMessageCounts,
} from 'Common/Models/Dashboard/dashboard.model';
import { TimeIntervalEnum } from 'Common/Enums/contact.enum';
import API from '../API';

export default class DashboardServices extends API {
    static baseUrl = process.env.REACT_APP_INSIGHTS_BASE_URL || '';

    constructor() {
        super(DashboardServices.baseUrl);
    }

    async getChatCounts({
        timeInterval,
        currentTime,
        waitTime,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        waitTime: number;
    }): Promise<DashboardChatCounts> {
        return this.Responsify<any>(
            await this.instance.get(
                `/consolidatedCounts?timeInterval=${timeInterval}&currentTime=${currentTime}&waitTime=${waitTime}`
            )
        );
    }

    async getUnansweredChatCounts({
        timeInterval,
        currentTime,
        waitTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        waitTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<UnansweredChatCount> {
        return this.Responsify<UnansweredChatCount>(
            await this.instance.post('/dashboard/unansweredChatCount', {
                timeInterval,
                currentTimestamp: currentTime,
                waitTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async getUnassignedChatCount({
        timeInterval,
        currentTime,
        waitTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        waitTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<UnassignedChatCount> {
        return this.Responsify<UnassignedChatCount>(
            await this.instance.post('/dashboard/unassignedChatCount', {
                timeInterval,
                currentTimestamp: currentTime,
                waitTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async getTotalMessageCountAnalytics({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<{ messageDirection: TotalMessageCounts }> {
        return this.Responsify<{ messageDirection: TotalMessageCounts }>(
            await this.instance.post('/dashboard/messageCounts', {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async getTotalChatCountAnalytics({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post('/dashboard/chatCounts', {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async postNewRelicLog(logObject: any): Promise<any> {
        return this.Responsify<any>(
            await this.instance.post('/instrument/apm', logObject)
        );
    }

    async getAgentPerformanceReport({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<AgentPerformanceReportTypes[]> {
        return this.Responsify<AgentPerformanceReportTypes[]>(
            await this.instance.post('/dashboard/agent-performance', {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async getChatStatusReport({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<ChatStatusReport> {
        return this.Responsify<ChatStatusReport>(
            await this.instance.post('/dashboard/chat-status-report', {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async getAverageSlaReport({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<AverageSlaReport> {
        return this.Responsify<AverageSlaReport>(
            await this.instance.post('/dashboard/avg-sla-report', {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }

    async getChatInitiatedByCustomer({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
        timeZone,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
        timeZone: string;
    }): Promise<{ data: ChatInitiatedMessageCounts }> {
        return this.Responsify<{ data: ChatInitiatedMessageCounts }>(
            await this.instance.post('/dashboard/chatsInitiated/lead', {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
                timeZone,
            })
        );
    }
}
